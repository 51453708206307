import React from 'react';
import Layout from '../components/layout';
import { graphql, StaticQuery } from 'gatsby';
import { IndexProps } from '../templates/index-page';
import Logo from '../components/logo';
import IMessage from '../components/imessage';
import BackgroundVideo from '../components/background-video';

const NotFoundPage = (props: IndexProps) => (
  <StaticQuery
    query={graphql`
      {
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
          frontmatter {
            siteLogo {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={(data: IndexProps) => (
      <Layout>
        <BackgroundVideo />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Logo
            image={data.markdownRemark.frontmatter.siteLogo.childImageSharp}
            alt={'Site Logo'}
          />
          <IMessage
            onClickContact={() => {}}
            messages={[
              'oops',
              `seems like you're lost... this page doesn't exist...`,
              <span>
                click <a href="https://airmilkshake.com">here</a> to go back
                home
              </span>,
            ]}
            style={{
              position: 'unset',
            }}
          />
        </div>
      </Layout>
    )}
  />
);

export default NotFoundPage;
